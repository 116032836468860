import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/X";
import { GitHub, Google } from "@mui/icons-material";
import {
  ButtonGroup,
  Chip,
  Grid,
  Grid2,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
} from "@mui/material";
import theme from "../theme";
import projectData from "../constant/projects.json";

const platofromIcon = {
  gPlay: Google,
  gitHub: GitHub,
};
type ProyectLinkType = "gPlay" | "gitHub";
type ProyecType = {
  name: string;
  year: string;
  description: string;
  image: string;
  teconologies: string[];
  links: {
    type: ProyectLinkType;
    url: string;
  }[];
};

function Proyect(data: { project: ProyecType; matchDownMd: boolean }) {
  const { project, matchDownMd } = data;
  return (
    <ImageListItem
      key={project.name}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <img
        srcSet={project.image}
        src={project.image}
        alt={project.name}
        loading="lazy"
        style={{
          borderRadius: "8px",
        }}
      />

      <ImageListItemBar
        position={"below"}
        title={project.name}
        subtitle={
          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexWrap: "wrap", // Cambiado a "wrap" para manejar múltiples chips
              overflow: "hidden", // Cambiado a "hidden" para evitar desbordamiento
            }}
          >
            {project.teconologies.map((tec) => (
              <Chip key={tec} label={tec} sx={{ margin: 0.5 }} /> // Ajustado el margen
            ))}
          </Box>
        }
        onClick={() => window.open(project.links[0].url, "_blank")}
      />
    </ImageListItem>
  );
  /* return (
    <Box
      sx={{
        mb: 3,
        p: 2,
        border: "1px solid #ccc",
        borderRadius: 1,
        //bgcolor: "#fff",
        textAlign: "center",
      }}
    >
      {/* <img
        src="https://enlace-a-imagen.com/imagen-proyecto2.png"
        alt="Proyecto 2"
        style={{ width: "100%", borderRadius: "8px", marginBottom: "16px" }}
      />}
      <Typography variant="body1" sx={{ fontWeight: "medium" }}>
        {proyect.name}
      </Typography>
      <Box sx={{ mt: 2 }}>
        {proyect.links.map((link) => {
          return (
            <Link
              href={link.url}
              target="_blank"
              color="inherit"
              sx={{ color: "#1976d2", textDecoration: "underline", mr: 2 }}
            >
              {React.createElement(platofromIcon[link.type])}
            </Link>
          );
        })}
      </Box>
    </Box>
  );*/
}

function SelectorSlide() {
  return (
    <ButtonGroup
      variant="text"
      aria-label="Basic button group"
      sx={{
        "& .MuiButton-root:hover": { backgroundColor: "transparent" },
        border: "none",
      }}
    >
      <Button
        sx={{ "&:hover": { textDecoration: "underline", color: "#1976d2" } }}
      >
        Todo
      </Button>
      <Button
        sx={{
          "&:hover": {
            textDecoration: "underline",
            color: "#1976d2",
            textUnderlineOffset: "10px",
          },
        }}
      >
        Mobil
      </Button>
      <Button
        sx={{
          "&:hover": {
            textDecoration: "underline",
            color: "#1976d2",
            textUnderlineOffset: "10px",
          },
        }}
      >
        WEB
      </Button>
      <Button
        sx={{
          "&:hover": {
            textDecoration: "underline",
            color: "#1976d2",
            textUnderlineOffset: "10px",
          },
        }}
      >
        Escritorio
      </Button>
    </ButtonGroup>
  );
}

function ProyectDetailList(data: {
  projects: ProyecType[];
  matchDownMd: boolean;
}) {
  const { projects, matchDownMd } = data;
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <ImageList
        sx={{
          maxWidth: matchDownMd ? 350 : 600,
        }}
        variant="masonry"
        cols={matchDownMd ? 1 : 2}
        gap={20}
      >
        {projects.map((item) => (
          <Proyect project={item} matchDownMd={matchDownMd} />
        ))}
      </ImageList>
    </div>
  );
}

export default function PersonalProyects() {
  const [projects, setProjects] = React.useState<ProyecType[]>(
    (projectData as any).reverse()
  );
  const matchDownMd = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <React.Fragment>
      <Container>
        <Typography variant="h6" component="h2" sx={{ mb: 4 }}>
          Proyectos Personales
        </Typography>
        <SelectorSlide />
        <ProyectDetailList projects={projects} matchDownMd={matchDownMd} />
      </Container>
    </React.Fragment>
  );
}
