import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import MyPresentation from "./components/MyPresentation";
import PersonalProyects from "./components/PersonalProyects";

export default function App() {
  return (
    <Container maxWidth="lg" sx={{ borderRadius: 2, p: 4 }}>
      <Box sx={{ my: 4, textAlign: "center" }}>
        <MyPresentation />
        <PersonalProyects />
      </Box>
    </Container>
  );
}
